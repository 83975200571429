import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);

      // this.events = {
      //   click: {
      //     tiroir: 'toggleSection',
      //     onglet: 'toggleOnglet',
      //     timeline: 'toggleTimeline',
      //     header: 'toggleHeader',
      //     ongletIntro: 'toggleOngletIntro',
      //     close: 'toggleClose',
      //   }
      // }
  }

  init() {
    const module = this;
    var mySubmitController = Marionette.Object.extend( {
      initialize: function() {
        this.listenTo( Backbone.Radio.channel( 'forms' ), 'submit:response', this.actionSubmit );
      },
      actionSubmit: function( response ) {
        setTimeout(() => {
          module.call('update', false, 'Scroll');
        }, 100);
        module.call('scrollTo', '#inscription_form', 'Scroll');
      },
    });
    // On Document Ready...
    $( document ).ready( function( $ ) {
        // Instantiate our custom field's controller, defined above.
        new mySubmitController();
    });
  }
}
