import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      var items = 1;
      var pagination = false;
      var navigation = false;
      var breakpoints = [];
      var autoplay = false;
      var direction = 'horizontal';
      var spaceBetween = 0;
      var grid = false;
      var slideChange = false;
      var countermax = false;
      
      if (this.getData('autoplay')) {
        autoplay = {
          delay: this.getData('autoplay'),
          disableOnInteraction: false,
        };
      }

      if (this.getData('items')) {
        items = parseInt(this.getData('items'));
      }

      if (this.getData('itemsAuto')) {
        items = this.getData('itemsAuto');
      }

      if(this.getData('spaceBetween')) {
        spaceBetween = this.getData('spaceBetween');
      }

      if (this.getData('pagination')) {
        pagination = {
          el: this.getData('pagination'),
          clickable: true,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
          disabledClass: '-disabled',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }

      if (this.getData('direction')) {
        direction = this.getData('direction');
      }

      if(this.getData('grid')) {
        grid = {
          rows: parseInt(this.getData('grid')),
        };
      }

      if(this.getData('countermax')) {
        countermax = this.getData('countermax');
      }

      if(this.getData('counter')) {
        let counter = this.getData('counter');
        slideChange = function(swiper) {
          var index = swiper.activeIndex + 1;
          if(countermax && index > countermax) {
            index = 1;
          }
          $(counter).text(index);
        };
      }

      new Swiper(this.el, {
        autoplay: autoplay,
        slidesPerView: items,
        spaceBetween: parseInt(spaceBetween),
        // Optional parameters
        direction: direction,
        //loop: true,
        autoHeight: false,

        // If we need pagination
        pagination: pagination,

        // Navigation arrows
        navigation: navigation,

        breakpoints: breakpoints,

        preloadImages: false,
        lazy: true,

        grid: grid,

        watchSlidesProgress: true,

        on: {
          realIndexChange: slideChange,
        },
      });
    }
}
